<!-- 树状选择器 -->
<template>
  <el-popover
    ref="popover"
    placement="bottom-start"
    trigger="click"
    @show="onShowPopover"
    @hide="onHidePopover">
    <el-tree
      ref="tree"
      class="select-tree"
      :highlight-current="true"
      :style="`min-width: ${treeWidth}`"
      :check-strictly="true" 
      :data="data"
      node-key="id"
      :props="props"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      :default-expand-all="true"
      :show-checkbox="true"
      @check-change="handleCheckChange"
      @node-click="onClickNode">
    </el-tree>
    <el-input
      slot="reference"
      ref="input"
      v-model="labelModel"
      @change="handleInputChange"
      clearable
      :style="`width: ${width}px`"
      :suffix-icon="suffixIcon"
      :placeholder="placeholder">
    </el-input>
  </el-popover>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    // 接收绑定参数
    value: String,
    // 输入框宽度
    width: String,
    // 选项数据
    options: {
      type: Array,
      required: true,
    },
    // 输入框占位符
    placeholder: {
      type: String,
      required: false,
      default: '请选择',
    },
    // 树节点配置选项
    props: {
      type: Object,
      required: false,
      default: () => ({
        parent: 'pid',
        value: 'id',
        label: 'nameCh',
        children: 'children',
      }),
    },
  },
  // 设置绑定参数
  model: {
    prop: 'value',
    event: 'slectNode'
  },
  computed: {
    // 是否为树状结构数据
    dataType() {
      const jsonStr = JSON.stringify(this.options);
      return jsonStr.indexOf(this.props.children) !== -1;
    },
    // 若非树状结构，则转化为树状结构数据
    data() {
      return this.dataType ? this.options : this.switchTree();
    },
  },
  watch: {
    labelModel(val) {
      if (!val) {
        this.valueModel = ''
        this.sltCheckedId=''
      }
      this.$refs.tree.filter(val);
    },

    value(val) {
      if(val&&val.length>0){
        this.labelModel = this.queryTree(this.data, val);
         this.labelInitVal=this.labelModel
         this.$refs.tree.setCurrentKey(val)
         this.sltCheckedId=val
        this.onCloseTree();
      }else{
        this.$refs.tree.setCurrentKey(null);
        this.sltCheckedId=''
      }
     
    },
  },
  data() {
    return {
      current:"",
      // 树状菜单显示状态
      showStatus: false,
      // 菜单宽度
      treeWidth: 'auto',
      // 输入框显示值
      labelModel: '',
      labelInitVal: '',
      // 实际请求传值
      valueModel: '',
      // 选项Id
      sltCheckedId: '',
      suffixIcon: 'el-icon-arrow-down',
      currentNode: {}
    };
  },
  created() {
    // 检测输入框原有值并显示对应 label
    if (this.value) {
      this.labelModel = this.queryTree(this.data, this.value)
      this.labelInitVal=this.labelModel
    }else{
      this.labelModel=''
      this.labelInitVal=''
    }
    // 获取输入框宽度同步至树状菜单宽度
    this.$nextTick(() => {
      this.treeWidth = `${(this.width || this.$refs.input.$refs.input.clientWidth) - 24}px`;
    });
  },
  mounted() {
    this.sltCheckedId=this.value
    this.valueModel=this.value
    
     
  },
  methods: {
    initSelected(label,value){
      this.labelModel=label
      this.valueModel =value;
      this.sltCheckedId=value;
    },
    // 单击节点
    onClickNode(node) {
      this.currentNode=null
      if(node.children==null|| (node.children!=null&&node.children.length==0)){
        this.labelModel = node[this.props.label];
        this.valueModel = node[this.props.value];
        this.sltCheckedId=node.id
        this.$refs.tree.setCheckedKeys([node.id])
        this.currentNode=node
        this.onCloseTree();
      }else{
        this.labelModel = ''
        this.valueModel = ''
      }
    },
    //改变CheckBox选中项
    handleCheckChange(node, checked) {
      let prevCheckedId=this.sltCheckedId;
      this.currentNode=null
      if (checked == true) {
        this.sltCheckedId=node.id;
        this.$refs.tree.setCheckedKeys([node.id])
        this.labelModel = node[this.props.label]
        this.valueModel = node[this.props.value]
        this.currentNode=node
        
      }else {
        if (this.sltCheckedId == node.id) {
          this.$refs.tree.setCheckedKeys([node.id])
          this.labelModel = node[this.props.label]
          this.valueModel = node[this.props.value]
          this.currentNode=node
        }
      }

      if(checked&&prevCheckedId!=node.id){
         this.onCloseTree();
      }

    },
    handleInputChange(value){
        if(value!=''&&value!=this.labelInitVal){
          this.labelModel=this.labelInitVal
        }

    },
    handleClear(){
      if(this.valueModel==''){
        this.$refs.tree.setCheckedKeys([])
        this.$emit('removeAll');
      }
    },
     // 通过递归的形式，获取角色下所有三级权限的id，并保存到 defKeys 数组中
    getLeafKeys(node) {
      // 如果当前 node 节点不包含 children 属性，则是三级节点
      if (node.children==null || node.children.length == 0) {
      // 除了最后一级所有节点disabled为true
        node.disabled = false;
      }
      if(node.children!=null && node.children.length > 0){
        node.disabled = true;
        node.children.forEach(item => this.getLeafKeys(item));
      }
    },
    getData() {
      var arr = this.data;
      arr.map(item => {
        this.getLeafKeys(item);
      });
      //console.log(arr)
    },
    // 偏平数组转化为树状层级结构
    switchTree() {
      return this.cleanChildren(this.buildTree(this.options, '0'));
    },
    // 隐藏树状菜单
    onCloseTree() {
      this.$refs.popover.showPopper = false;

      this.$refs.tree.setCurrentKey(null);
    },
    // 显示时触发
    onShowPopover() {
      this.showStatus = true;
      this.suffixIcon='el-icon-arrow-up'
      this.$refs.tree.filter(false);
      //console.log("sltCheckedId:"+this.sltCheckedId)
      if( this.sltCheckedId==''){
        this.$refs.tree.setCheckedKeys([])
      }else{
        this.$refs.tree.setCheckedKeys([this.sltCheckedId])
      }
     

    
    },
    // 隐藏时触发
    onHidePopover() {
      this.showStatus = false;
      this.suffixIcon='el-icon-arrow-down'
      this.$emit('slectNode', this.valueModel);
      this.$emit('getCurrentNode', this.currentNode);
    },
    // 树节点过滤方法
    filterNode(query, data) {
      if (!query) return true;
      return data[this.props.label].indexOf(query) !== -1;
    },
    // 搜索树状数据中的 ID
    queryTree(tree, id) {
      let stark = [];
      stark = stark.concat(tree);
      while (stark.length) {
        const temp = stark.shift();
        if (temp[this.props.children]) {
          stark = stark.concat(temp[this.props.children]);
        }
        if (temp[this.props.value] === id) {
          return temp[this.props.label];
        }
      }
      return '';
    },
    // 将一维的扁平数组转换为多层级对象
    buildTree(data, id = '0') {
      const fa = (parentId) => {
        const temp = [];
        for (let i = 0; i < data.length; i++) {
          const n = data[i];
          if (n[this.props.parent] === parentId) {
            n.children = fa(n.rowGuid);
            temp.push(n);
          }
        }
        return temp;
      };
      return fa(id);
    },
    // 清除空 children项
    cleanChildren(data) {
      const fa = (list) => {
        list.map((e) => {
          if (e.children.length) {
            fa(e.children);
          } else {
            delete e.children;
          }
          return e;
        });
        return list;
      };
      return fa(data);
    },
  },
};
</script>

<style>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
    max-width: 379px;
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }
  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }
  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }
  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }
  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }


  .el-tree-node  .is-leaf + .el-checkbox .el-checkbox__inner{
      display: inline-block;
  }
  .el-tree-node .el-checkbox .el-checkbox__inner{
      display: none;
  }
</style>