<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="formInline.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="director">
          <el-select v-model="formInline.director">
            <el-option label="请选择" value="-请选择-"></el-option>
            <el-option v-for="(item, index) of principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目状态" prop="projectStatus">
          <el-select v-model="formInline.projectStatus">
            <el-option label="进行中" value="1"></el-option>
            <el-option label="暂停中" value="2"></el-option>
            <el-option label="已验收" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="立项时间范围">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="车型" prop="modelCode">
          <el-input v-model="formInline.modelCode" placeholder="请输入车型"></el-input>
        </el-form-item>
        <el-form-item label="年款" prop="modelYear">
          <el-input v-model="formInline.modelYear" placeholder="请输入年款"></el-input>
        </el-form-item>
        <el-form-item label="风险状态" prop="riskStatus">
          <el-select v-model="formInline.riskStatus">
            <el-option label="无风险" value="1"></el-option>
            <el-option label="低风险" value="2"></el-option>
            <el-option label="中风险" value="3"></el-option>
            <el-option label="高风险" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="handelAdd()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="序号" type="index" width="60"></el-table-column>
        <el-table-column label="项目名称" prop="name" width="190"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="190"></el-table-column>
        <el-table-column label="项目状态" prop="projectStatus" width="140">
          <template slot-scope="{row}">
            <span v-if="row.projectStatus === 1">进行中</span>
            <span v-if="row.projectStatus === 2">暂停中</span>
            <span v-if="row.projectStatus === 3">已验收</span>
          </template>
        </el-table-column>
        <el-table-column label="车型" prop="modelCode" width="140">
           <template slot-scope="{row}">
            <span v-if="row.modelCode !=null && row.modelCode !='' ">{{row.modelName+'('+row.modelCode+')'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="年款" prop="modelYear" width="130"></el-table-column>
        <el-table-column label="立项时间" prop="establishTime" width="190" :formatter="dateFormat"></el-table-column>
        <el-table-column label="节点时间" prop="deadlineTime" width="190" :formatter="dateFormat"></el-table-column>
        <el-table-column label="风险状态" prop="riskStatus" width="130">
          <template slot-scope="{row}">
            <span v-if="row.riskStatus === 1" style="color:#009933">无风险</span>
            <span v-if="row.riskStatus === 2" style="color:#cc0000">低风险</span>
            <span v-if="row.riskStatus === 3" style="color:#cc0000">中风险</span>
            <span v-if="row.riskStatus === 4" style="color:#cc0000">高风险</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetail(row)">详情</el-button>
            <el-button type="primary" size="mini" @click="handelEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#ff5722" @click="handelDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate" :close-on-click-modal="false">
        <el-form v-if="dialogStatus === 'detail'" ref='temp' :model="temp" label-position="center">
          <el-form-item label="公司" prop="firmId" :label-width="formLabelWidth">
            <el-select v-model="temp.firmId" readonly>
              <el-option v-for="(item, index) of firmList" :key="index" :label="item.nameCh" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-input v-model="temp.principalName" readonly></el-input>
          </el-form-item>
          <el-form-item label="立项时间" prop="establishTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.establishTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="项目节点" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="项目状态" prop="projectStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.projectStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.riskStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型" prop="modelCode" :label-width="formLabelWidth">
            <el-input v-model="temp.modelCode" readonly></el-input>
          </el-form-item>
          <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
            <el-input v-model="temp.modelYear" readonly></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" readonly></el-input>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit' " ref='temp' :model="temp" :rules="rules"  label-position="center">
          <el-form-item label="公司" prop="firmId" :label-width="formLabelWidth">
            <el-select v-model="temp.firmId" @change="firmChanged" :disabled="editStatus">
              <el-option v-for="(item, index) of firmList" :key="index" :label="item.nameCh" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" placeholder="请输入项目名称"   show-word-limit maxlength="100"    style="padding:0 55px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-select v-model="temp.principal">
              <el-option v-for="(item, index) of principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="立项时间" prop="establishTime" :label-width="formLabelWidth">
           <el-date-picker type="date" v-model="temp.establishTime" placeholder="请选择立项时间"></el-date-picker> 
          </el-form-item>
          <el-form-item label="项目节点" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" placeholder="请选择项目节点"></el-date-picker>
          </el-form-item>
          <el-form-item label="项目状态" prop="projectStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.projectStatus">
              <el-option v-for="(item, index) of projectStatusList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.riskStatus">
              <el-option v-for="(item, index) of riskStatusList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车型" prop="modelCode" :label-width="formLabelWidth">
            <select-tree ref="modelSelectTree" :options="trainList" v-model="temp.modelCode"  :props="defaultProps"  @getCurrentNode="getCurrentNode" placeholder="请选择车型"  />
          </el-form-item>
          <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
            <el-select v-model="temp.modelYear">
              <el-option v-for="(item,index) in trainYear" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注" show-word-limit maxlength="100"    style="padding:0 48px 0 0" ></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : updateData('temp')">
              立即提交
            </el-button>
            <el-button @click="resetForm('temp')" :disabled="editStatus">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 风险状态不能为null
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import SelectTree from '@/components/TreeView/SelectTree.vue';
import Pagination from '@/components/Pagination'
import { projectData, trainInfo, yearInfo, projectSearch, projectUser, projectFirm, projectAdd, projectEdit, projectDel } from '@/api/api.js';
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination, SelectTree },
  data () {
    return {
      formInline: {
        projectName: '',
        director: '',
        projectStatus: '',
        begintime: '',
        endtime: '',
        modelName: '',
        modelCode: '',
        modelYear: '',
        riskStatus: ''
      },
      temp: {
        id: '',
        firmId: '',
        name: '',
        principal: '',
        establishTime: '',
        deadlineTime: '',
        projectStatus: '',
        riskStatus: '',
        modelName: '',
        modelCode: '',
        modelYear: '',
        remark: ''
      },
      sltModelYear: '',
      riskStatusList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      projectStatusList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      dialogFormVisible: false,
      dialogStatus: '',
      editStatus:false,
      textMap: {
        detail: '项目明细',
        edit: '编辑项目',
        add: '新增项目'
      },
      resultList: [],
      firmList: [],
      trainList: [],
      trainYear: [],
      // 数据默认字段
      defaultProps: {
        parent: 'pid',   // 父级唯一标识
        value: 'id',          // 唯一标识
        label: 'nameCh',       // 标签显示
        children: 'children', // 子级
      },
      principalList: [],
      modelName: "",
      pagesize: 10,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px',
      rules: {
        firmId: [{ required: true, message: '公司不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '项目名称不能为空', trigger: 'blur' }],
        principal: [{ required: true, message: '负责人不能为空', trigger: 'blur' }],
        establishTime: [{ required: true, message: '立项时间不能为空', trigger: 'blur' }],
        deadlineTime: [{ required: true, message: '车系不能为空', trigger: 'blur' }],
        modelCode: [{ required: true, message: '车型不能为空', trigger: 'blur' }],
        modelYear: [{ required: true, message: '年款不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {     
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.projectName,
        principal: this.formInline.director,
        projectStatus: this.formInline.projectStatus,
        establishTime: this.formInline.begintime,
        deadlineTime: this.formInline.endtime,
        modelCode: this.formInline.modelCode,
        modelYear: this.formInline.modelYear,
        riskStatus: this.formInline.riskStatus
      }
      projectData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    getTrainList(firmId){
      var params = {
        firmId: firmId
      }
      trainInfo(params).then(res => {
        if(res.data.code==100){
          this.trainList = res.data.data
        }
      })
    },
    getTrainYearList(trainId){
      var params = {
        code: trainId
      }
      yearInfo(params).then(res => {
        if(res.data.code==100){
          let yearList = res.data.data
          if(!this.campareSameArray(yearList,this.trainYear)){
            if(this.trainYear&&this.trainYear.length>0){
                 this.temp.modelYear=''
            }
            this.trainYear =yearList
          }
          // if(yearList&&yearList.length>0){
          //    if(this.trainYear&&this.trainYear.length>0){
          //        if(this.trainYear.sort().toString()!=yearList.sort().toString()){
          //          this.trainYear=yearList
          //          this.temp.modelYear=''
          //        }
          //    }else{
          //       this.trainYear=yearList
          //    }
          // }else{
          //    this.temp.modelYear=''
          //    this.trainYear =[]
          // }
          
        }else{
          this.temp.modelYear=''
          this.trainYear =[]
        }
      })
    },
    campareSameArray(arr1,arr2){
      let ret=false
      if(arr1&&arr1.length>0&&arr2&&arr2.length>0){
         if(arr1.sort().toString()==arr2.sort().toString()){
             ret=true
         }
      }
      return ret
    },
    firmChanged(value){
      if(value!=''){
        this.getTrainList(value)
        this.temp.modelCode=''
        this.temp.modelYear=''
      }
    },
    getCurrentNode(node){
      //  console.log("选中的节点。。。。")
      //  console.log(node)
      if(this.temp.modelCode!=null&&node!=null){
        this.getTrainYearList(node.id)
        if(this.temp.modelCode!=node.id){
           this.temp.modelYear=''
        }
      
        this.$refs['temp'].validateField('modelCode')
      }
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.projectName,
        principal: this.formInline.director,
        projectStatus: this.formInline.projectStatus,
        establishTime: this.formInline.begintime,
        deadlineTime: this.formInline.endtime,
        modelCode: this.formInline.modelCode,
        modelYear: this.formInline.modelYear,
        riskStatus: this.formInline.riskStatus
      }
      projectSearch(params).then(res => {
        this.total = res.data.count
        this.resultList = res.data.data
      })
      this.dataList()
    },
    // 负责人
    getUserList () {
      projectUser().then(res => {
        if (res.data.code === 100) {
          this.principalList = res.data.data
        }
      })
    },
    // 公司
    getFirmList () {
      projectFirm().then(res => {
        if (res.data.code == 100) {
          this.firmList = res.data.data
        }
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.temp.resetFields()
    },
    resetTemp () {
      this.temp = {
        firmId: '',
        name: '',
        principal: '',
        principalName: '',
        establishTime: '',
        deadlineTime: '',
        projectStatus: '',
        riskStatus: '',
        modelName: '',
        modelCode: '',
        modelYear: '',
        remark: ''
      }
    },
    // 风险状态
    getStatusCode () {
     
      for (var j = 0; j < this.riskStatusList.length; j++) {
        if (this.riskStatusList[j].code === this.temp.riskStatus) {
          this.temp.riskStatus = this.riskStatusList[j].name
        }
      }
      for (var k = 0; k < this.projectStatusList.length; k++) {
        if (this.projectStatusList[k].code === this.temp.projectStatus) {
          this.temp.projectStatus = this.projectStatusList[k].name
        }
      }
    },
    updateStatus () {
     
      for (var j = 0; j < this.riskStatusList.length; j++) {
        if (this.riskStatusList[j].name === this.temp.riskStatus) {
          this.temp.riskStatus = this.riskStatusList[j].code
        }
      }
      for (var k = 0; k < this.projectStatusList.length; k++) {
        if (this.projectStatusList[k].name === this.temp.projectStatus) {
          this.temp.projectStatus = this.projectStatusList[k].code
        }
      }
    },
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
           this.$refs[temp].resetFields()
      }
    },
    // 增加
    handelAdd () {
      this.resetTemp()
      this.trainList=[]
      this.trainYear=[]
      this.dialogFormVisible = true
      this.dialogStatus = 'add'
      this.editStatus=false
      var _this=this
      setTimeout(function(){
         _this.$refs.modelSelectTree.initSelected('','')
      },0);
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          var params = new URLSearchParams()
          params.append('firmId', this.temp.firmId)
          params.append('name', this.temp.name)
          params.append('principal', this.temp.principal)
          params.append('establishTime', obj.getMyDate(this.temp.establishTime))
          params.append('deadlineTime', obj.getMyDate(this.temp.deadlineTime))
          params.append('projectStatus', this.temp.projectStatus)
          params.append('riskStatus', this.temp.riskStatus)
          params.append('modelCode', this.temp.modelCode)
          params.append('modelYear', this.temp.modelYear)
          params.append('remark', this.temp.remark)
          projectAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }else{
          this.$message.error('请完善项目信息')
        }
      })
     
    },
    // 详情
    handelDetail (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.getStatusCode()
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 编辑
    handelEdit (row) {
      var _this=this
      this.resetTemp()
      this.trainList=[]
      this.trainYear=[]

      _this.getTrainList(row.firmId)
      _this.getTrainYearList(row.modelCode)
      this.temp = Object.assign({}, row)
    
      setTimeout(function(){
        // _this.$refs.modelSelectTree.setInputValue(row.modelName)
         _this.$refs.modelSelectTree.initSelected(row.modelName,row.modelCode)
      },0);
    
      this.dialogFormVisible = true
      this.dialogStatus = 'edit'
      this.editStatus=true
      
   

    },
    updateData (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          var params = new URLSearchParams()
          params.append('id', this.temp.id)
          params.append('firmId', this.temp.firmId)
          params.append('name', this.temp.name)
          params.append('principal', this.temp.principal)
          params.append('establishTime', obj.getMyDate(this.temp.establishTime))
          params.append('deadlineTime', obj.getMyDate(this.temp.deadlineTime))
          params.append('projectStatus', this.temp.projectStatus)
          params.append('riskStatus', this.temp.riskStatus)
          params.append('modelCode', this.temp.modelCode)
          params.append('modelYear', this.temp.modelYear)
          params.append('remark', this.temp.remark)
          projectEdit(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('请完善项目信息')
        }
      })
    },
    // 删除
    handelDelete (row) {
      var _this=this
      this.$confirm('确定删除 ' + row.name + '?', '删除项目', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        projectDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        })
      }).catch((error)=>{
        _this.$message.error('删除失败')
      })
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.dataList()
    this.getUserList()
    this.getFirmList()
     this.$nextTick(() => {
       _this.$refs.modelSelectTree.setInputValue('')
      });
  }

}
</script>
